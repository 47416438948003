
// @ is an alias to /src
import IPMap from "../components/IPMap";
import IPFilter from "@/components/IPFilter";
// import {mapMutations} from "vuex";

export default {
  name: 'Home',
  components: {
    IPFilter,
    IPMap,
  }
}
