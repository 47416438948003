
import {mapMutations, mapState} from "vuex";
// TODO
// import noUiSlider from "nouislider";
// import 'nouislider/dist/nouislider.css';

export default {
  name: "IPFilter",
  data() {
    return {
      filterExpanded: false
    }
  },
  mounted() {
    // TODO
    // noUiSlider.create(this.$refs.slider, {
    //   start: [20, 80],
    //   connect: true,
    //   range: {
    //     'min': 0,
    //     'max': 100
    //   }
    // });
  },
  computed: {
    localHoods: {
      get() {
        return this.filteredHoods;
      },
      set(newValue) {
        this.setFilteredHoods({newFilteredHoods: newValue});
      }
    },

    localAuthors: {
      get() {
        return this.filteredAuthors;
      },
      set(newValue) {
        console.log(newValue);
        this.setFilteredAuthors({newFilteredAuthors: newValue});
      }
    },

    ...mapState([
        'authors',
        'filteredAuthors',
        'hoods',
        'filteredHoods'
    ])
  },
  methods: {
    openFilter() {
      this.filterExpanded = true;
    },
    closeFilter() {
      this.filterExpanded = false;
    },
    ...mapMutations([
        'setFilteredHoods',
        'setAllFilteredHoods',
        'unsetAllFilteredHoods',
        'setAllFilteredAuthors',
        'unsetAllFilteredAuthors',
        'setFilteredAuthors',
        'setAllFilters',
        'unsetAllFilters'
    ])
  }
}
