
import router from "@/router";
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';


export default {
  name: "IPPopup",
  props: ['place'],
  components: {
    Splide,
    SplideSlide,
  },

  data() {
    return {
      splideOptions: {
        mouse: true,
        rewind: true,
        pagination: false,
        height: '100%',
        width: '100%'
      }
    }
  },

  mounted() {
    window.addEventListener("keydown", this.escKeyListener);
  },

  unmounted() {
    window.removeEventListener("keydown", this.escKeyListener);
  },

  methods: {
    closePopup() {
      router.push({name: 'Home'});
    },

    escKeyListener(event) {
      if (event.key === "Escape") {
        this.closePopup();
      }
    },
  }
}
